<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :loading="loading"
  >
    <div slot="actions">
      <div class="field has-addons">
        <b-dropdown
          aria-role="list"
          position="is-bottom-left"
          scrollable
          max-height="400px"
          append-to-body
        >
          <div slot="trigger">
            <b-button
              icon-left="dots-vertical"
              :size="$device.mobile ? 'is-small' : ''"
              type="is-default"
              rounded
            />
          </div>

          <b-dropdown-item
            v-if="$can('create', 'settings.agents')"
            aria-role="listitem"
            @click="openForm"
          >
            <b-icon
              icon="plus"
              size="is-small"
            />
            {{ $t("new_agent") }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <Card
      v-if="$can('read', 'settings.agents')"
      content-class="c-table"
    >
      <div class="header-container">
        <div />

        <b-field>
          <p class="control">
            <b-button
              size="is-small"
              :type="active ? 'is-primary' : ''"
              @click="active = true"
            >
              {{ $tc("active", 2) }}
            </b-button>
          </p>
          <p class="control">
            <b-button
              size="is-small"
              :type="!active ? 'is-primary' : ''"
              @click="active = false"
            >
              {{ $tc("inactive", 2) }}
            </b-button>
          </p>
        </b-field>
      </div>
      <hr class="my-0">
      <b-table
        :loading="loading"
        :data="filteredItems"
        :total="total"
        hoverable
        default-sort-direction="asc"
        default-sort="fname"
        paginated
        :per-page="pagination.perPage"
        pagination-position="top"
        pagination-size="is-small"
        :current-page="pagination.page"
      >
        <template v-for="head in headers">
          <b-table-column
            :key="head.value"
            :field="head.value"
            :label="head.label"
            :width="head.width"
            :centered="head.centered"
            :sortable="head.sortable"
            :searchable="head.searchable"
            v-bind="head"
            header-class="sticky-header"
          >
            <template
              v-if="head.searchable && head.value == 'id'"             
              #searchable="props"
            >
              <b-input
                v-model="props.filters[props.column.field]"
                :placeholder="placeholders.number['2']"
                rounded
                size="is-small"
              />
            </template> 
            <template
              v-else-if="head.searchable && head.value == 'fname'"             
              #searchable="props"
            >
              <b-input
                v-model="props.filters[props.column.field]"
                :placeholder="placeholders.user.fname"
                rounded
                size="is-small"
              />
            </template>  
            <template
              v-else-if="head.searchable && head.value == 'lname'"             
              #searchable="props"
            >
              <b-input
                v-model="props.filters[props.column.field]"
                :placeholder="placeholders.user.lname"
                rounded
                size="is-small"
              />
            </template>  
            <template
              v-else-if="head.searchable && head.value == 'email'"             
              #searchable="props"
            >
              <b-input
                v-model="props.filters[props.column.field]"
                :placeholder="placeholders.user.email"
                rounded
                size="is-small"
              />
            </template>  
            <template
              v-else-if="head.searchable && head.value == 'language'"             
              #searchable="props"
            >
              <b-input
                v-model="props.filters[props.column.field]"
                :placeholder="placeholders.country.language"
                rounded
                size="is-small"
              />
            </template>  
            <template
              v-else-if="head.searchable && head.value == 'warehouses'"             
              #searchable="props"
            >
              <b-input
                v-model="props.filters[props.column.field]"
                :placeholder="placeholders.country.label"
                rounded
                size="is-small"
              />
            </template>  
            <template
              v-else-if="head.searchable && head.value == 'role'"             
              #searchable="props"
            >
              <b-input
                v-model="props.filters[props.column.field]"
                :placeholder="placeholders.role.display"
                rounded
                size="is-small"
              />
            </template>  
            <template
              v-else-if="head.searchable && head.value == 'supplier'"             
              #searchable="props"
            >
              <b-input
                v-model="props.filters[props.column.field]"
                :placeholder="placeholders.supplier.name"
                rounded
                size="is-small"
              />
            </template>  
             
            <!-- id -->
            <template
              v-if="head.value == 'id'"
              v-slot="props"
            >
              <router-link
                v-if="$can('update', 'settings.agents')"
                :to="`/settings/agents/${props.row.id}`"
              >
                {{ props.row.id }}
              </router-link>
              <span v-else>{{ props.row.id }}</span>
            </template>
            <template
              v-else-if="head.value == 'fname'"
              v-slot="props"
            >
              {{ props.row.fname || "-" }}
            </template>
            <template
              v-else-if="head.value == 'lname'"
              v-slot="props"
            >
              {{ props.row.lname || "-" }}
            </template>
            <template
              v-else-if="head.value == 'email'"
              v-slot="props"
            >
              {{ props.row.email || "-" }}
            </template>
            <template
              v-else-if="head.value == 'language'"
              v-slot="props"
            >
              {{ props.row.language || "-" }}
            </template>
            <template
              v-else-if="head.value == 'warehouses'"
              v-slot="props"
            >
              {{ props.row.warehouses || "-" }}
            </template>
            <template
              v-else-if="head.value == 'role'"
              v-slot="props"
            >
              {{ props.row.role || "-" }}
            </template>
            <template
              v-else-if="head.value == 'supplier'"
              v-slot="props"
            >
              {{ props.row.supplier || "-" }}
            </template>
            <template
              v-else-if="head.value == 'actions'"
              v-slot="props"
            >
              <b-dropdown
                aria-role="list"
                position="is-bottom-left"
                scrollable
                max-height="400px"
                append-to-body
              >
                <div slot="trigger">
                  <b-icon
                    icon="dots-vertical"
                    class="is-clickable"
                    size="is-small"
                  />
                </div>

                <b-dropdown-item
                  v-if="$can('update', 'settings.agents')"
                  aria-role="listitem"
                  has-link
                >
                  <router-link :to="`/settings/agents/${props.row.id}`">
                    <b-icon
                      icon="eye"
                      size="is-small"
                    />
                    {{ $t("view") }}
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('read', 'settings.agents') && active"
                  aria-role="listitem"
                  @click="requestPassword(props.row.id)"
                >
                  <b-icon
                    icon="lock"
                    size="is-small"
                  />
                  {{ $t("request_password") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('delete', 'settings.agents') && active"
                  aria-role="listitem"
                  @click="confirmDeleting(props.index, props.row.id)"
                >
                  <b-icon
                    icon="delete"
                    type="is-danger"
                    size="is-small"
                  />
                  {{ $t("delete") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('delete', 'settings.agents') && !active"
                  aria-role="listitem"
                  @click="restore(props.index, props.row.id)"
                >
                  <b-icon
                    icon="refresh"
                    type="is-success"
                    size="is-small"
                  />
                  {{ $t("restore") }}
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table-column>
        </template>

        <EmptyBlock
          slot="empty"
          icon="file-powerpoint-box"
        />
      </b-table>
      <Pagination
        :per-page="pagination.perPage"
        :per-pages="rowsPerPage"
        :total="total"
        :current-page="pagination.page"
        @update-per-page="val => (pagination.perPage = val)"
        @update-current-page="val => (pagination.page = val)"
      />
    </Card>
  </Page>
</template>

<script>
export default {
  data() {
    return {
      rowsPerPage: [50, 100, 200],
      pagination: { perPage: 50, page: 1 },
      items: [],
      filteredItems: [],
      total: 0,
      loading: true,
      roles: [],
      warehouses: [],
      languages: [],
      suppliers: [],
      warehouse_id: null,
      active: true,
      searchFocused: false,
      search: "",
      headers: [
        {
          label: this.$t("id"),
          value: "id",
          sortable: true,
          searchable: true,
          width: "80",
        },
        {
          label: this.$t("fname"),
          value: "fname",
          sortable: true,
          searchable: true,
          width: "150",
        },
        {
          label: this.$t("lname"),
          value: "lname",
          sortable: true,
          searchable: true,
          width: "150",

        },
        {
          label: this.$t("email"),
          value: "email",
          sortable: true,
          searchable: true,
          width: "200",
        },
        {
          label: this.$tc("language",1),
          value: "language",
          sortable: true,
          searchable: true,
          width: "140",
        },
        {
          label: this.$tc("warehouse", 1),
          value: "warehouses",
          sortable: true,
          searchable: true,
          width: "250",
        },
        {
          label: this.$tc("role",1),
          value: "role",
          sortable: true,
          searchable: true,
          width: "180",
        },
        {
          label: this.$tc('supplier', 1),
          value: "supplier",
          sortable: true,
          searchable: true,
          width: "150",
        },
        {
          label: this.$t("actions"),
          value: "actions",
          sortable: false,
          searchable: false,
          centered: true,
          width: "80",
        },
      ]
    };
  },
  watch: {
    active() {
      this.getAgents();
    },
    search() {
      this.filterSearch();
    }
  },
  created() {
    this.updateTitle(this.$i18n.tc("agent", 2));
    this.getAgents();
  },
  mounted() {
    this.$bus.$on("update-agent", data => {
      data.language = data.language.language;
      data.role = data.role.label;
      data.supplier = data.supplier.name;
      this.items.unshift(data);
      this.filterSearch();
    });
  },
  destroyed() {
    this.$bus.$off("update-agent");
  },
  methods: {
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    },
    openForm() {
      this.openDrawer("agentForm", {
        languages: this.languages,
        roles: this.roles,
        suppliers: this.suppliers,
        warehouses: this.warehouses
      });
    },
    confirmDeleting(index, agent_id) {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.delete(index, agent_id)
      });
    },
    delete(index, agent_id) {
      this.$axios
        .delete("agents/" + agent_id)
        .then(() => {
          index = this.items.findIndex(i => i.id == agent_id);
          this.items.splice(index, 1);
          this.filterSearch();
          this.$root.notify(this.$t("successfully_deleted"), "is-success");
        })
        .catch(e => this.clientError(e));
    },
    restore(index, agent_id) {
      this.$axios
        .patch("agents/" + agent_id + "/restore")
        .then(() => {
          index = this.items.findIndex(i => i.id == agent_id);
          this.items.splice(index, 1);
          this.filterSearch();
          this.$root.notify(this.$t("successfully_restored"), "is-success");
        })
        .catch(e => this.clientError(e));
    },
    requestPassword(agent_id) {
        this.$axios
          .post("agents/" + agent_id + "/password/request")
          .then(() => {
            this.notify(
              this.$i18n.t("request_successfully_sent"),
              "is-success"
            );
          })
          .catch(e => this.clientError(e));
    },
    filterSearch() {
      this.filteredItems = this.items.filter(obj =>
        `${obj.id}${obj.fname}${obj.lname}${obj.email}${obj.language}${obj.role}${obj.supplier}`
          .toLowerCase()
          .includes(this.search.toLowerCase())
      );
      this.total = this.filteredItems.length;
    },
    getAgents() {
      if (!this.$can("read", "settings.agents")) return;
      this.loading = true;
      let params = `?active=${this.active ? 1 : 0}`;

      if (this.warehouse_id) params += `&warehouse_id=${this.warehouse_id}`;

      this.$axios
        .get(`/agents${params}`)
        .then(({ data }) => {
          this.items = data.list;
          this.filterSearch();
          this.warehouses = data.filters.warehouses;
          if (!this.warehouse_id)
            this.warehouse_id = this.warehouses[0] && this.warehouses[0].id;
          this.roles = data.filters.roles;
          this.languages = data.filters.languages;
          this.suppliers = data.filters.suppliers;
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
